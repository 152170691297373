@import "presets";
@import "fx";
@import "avm-icons";

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#app-target {
  width: 100%;
  max-width: $w-max;
  margin: 0;
  position: relative;
  min-height: calc(100vh - 4rem);
  padding-bottom: 4rem;

  @media #{$oversized} {
    border: 0;
  }
}

.company-logo {
  @if $is-opta {
    @include company-logo;
    position: absolute;
    bottom: 50px;
    right: 50px;
  } @else {
    display: none;
  }
}

.company-top-logo {
  @if $is-opta {
    @include company-logo;
    position: absolute;
    top: 8px;
    left: 40px;
  } @else {
    display: none;
  }
}

body {
  @include body-common;
}

a {
  text-decoration: none;
  color: $c-grey-dk-2;

  &:not(.big-button):hover {
    color: $c-high-lt;
  }
}

.page-wrap {
  text-align: left;

  @media #{$xlarge} {
    width: $w-large;
    margin: 0 auto;
  }
}

footer {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 1rem;
  z-index: 1;
  font: normal 1rem $font-sans;
  color: $c-grey-dk-2;
  text-align: center;

  &.verisk {
    color: $c-high;
  }
}

.top-header {
  background-color: $c-grey-lt-2;
}

body.body-styled {
  @include body-styled;

  .top-header {
    background-color: transparent;
  }

  .company-top-logo {
    display: none; // avoid 2 logos on login/search
  }

  footer {
    position: fixed;
    max-width: $w-screen-max;
  }
}

.page-footer {
  @if $is-opta {
    &.verisk {
      display: none;
    }
  } @else {
    &.opta {
      display: none;
    }
  }
}

:disabled {
  cursor: not-allowed;
}

input,
button {
  outline: none;
  font-family: $font-sans;
}

input,
textarea {
  &:disabled {
    background-color: $c-grey-lt-2;
    cursor: not-allowed;
  }
}

button,
.button,
[role="button"] {
  cursor: pointer;

  &:disabled {
    cursor: not-allowed;
  }
}

h2,
h3,
h4,
.h2,
.h3,
.h4,
button {
  font-family: $font-sans;
  font-weight: $fw-bold;
}

h2 {
  margin: 0.5rem 0;
  font-size: 20px;
  color: $c-heading;
  text-transform: capitalize;

  &.intro-title {
    font-size: 2.4rem;
    margin-bottom: 1rem;
    letter-spacing: 2px;
    text-align: center;
    color: $c-high;
  }
}

h3 {
  font-size: 18px;
  color: $c-grey-dk-2;

  &.valuation.fr {
    font-size: 16px;
  }
}

h4,
.h4 {
  font-size: 1.1rem;
}

.relative {
  position: relative;
}

strong,
.strong {
  font-weight: $fw-bold;
}

.error-text,
.text-error {
  color: $c-error;
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.table {
  display: table;
}

.table-row,
.tr {
  display: table-row;
}

.table-cell,
.th,
.td {
  display: table-cell;
}

.big-button {
  @include avm-button;
}

.shimmer-gradient {
  animation: shimmer 1s infinite;
  background: linear-gradient(0deg, #dcdcdc, #ccc, #e2e2e2);
  background-size: 200% 200%;
}

// "Street View is not available" placeholder
.no-street-view {
  // width/height may be set inline
  background-position: center center;
  background-size: contain;

  &::after {
    content: " ";
  }
}

.session-loader {
  font-size: 0.9rem;
  color: $c-grey-dk-2;
  animation: 1s infinite blink;
}

// to append language-dependent trailing colon
.add-colon {
  &::after {
    content: ":";
  }

  &.fr::after {
    content: " :";
  }
}

.blink-1s {
  animation: 1s infinite blink;
}
