@import "../presets";

.address-stack {
  position: absolute;
  top: 56px;
  left: 21px;
  z-index: 1;
  text-align: left;

  .btn-trigger {
    @include bare-button;
    font-size: 1.1rem;
    color: $c-high;

    &.btn-expand::before {
      content: "+";
    }

    &.btn-collapse::before {
      content: "×";
    }
  }
}

.address-stack--dropdown {
  background-color: white;
  padding: 4px 0;
  border-radius: 4px;
  margin-top: 0.5rem;

  li {
    list-style-type: none;
    font-size: 0.8rem;
    padding: 2px 8px;

    &:hover {
      text-decoration: underline;
    }

    &.btn-reset {
      text-align: center;
      color: $c-high;

      &::before {
        content: "× ";
      }
    }
  }
}
