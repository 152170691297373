@import "../presets";

.language {
  color: $c-high;
  &.en {
    &::before {
      content: "FR";
    }
  }
  &.fr {
    &::before {
      content: "EN";
    }
  }
}
