/*
No output here, please. This common module is for variables and mixins only.
If any CSS output is produced here, it will be multiplied by the number of imports.
*/

// For more font options see  https://fonts.google.com/specimen/Open+Sans?selection.family=Open+Sans
@import url("https://fonts.googleapis.com/css?family=Open+Sans:400,400i,600,600i&display=swap");

// branding flag
$is-opta: false !default;

$c-high: #00358e; // Verisk-blue, to replace Opta orange
$c-high-lt: #2a7de1;

@if $is-opta {
  $c-high: #d14f27; // Opta orange
  $c-high-lt: $c-high;
}

$font-sans: "Open Sans", Arial, Helvetica, sans-serif;

// font weight
$fw-normal: 400;
$fw-bold: 600;

// base colours
$c-white: #fff;
$c-black: #000;

// greyscale
$c-grey-lt-2: #f3f2f4;
$c-grey-lt: #d7d6dc;
$c-grey: #ccc;
$c-grey-dk-4: #c3c3c3;
$c-grey-dk-3: #939295;
$c-grey-dk-2: #807f83;
$c-grey-dk: #404040;

$c-green: #42b34a;

$c-red: rgb(234, 51, 35);
$c-yellow: rgb(254, 254, 84);

// customization
$c-body-text: $c-grey-dk;
$c-heading: $c-grey-dk;
$c-error: $c-high;

$bc-body: $c-white;

$fs-body: 16px;

//----------------------------------------- media queries
$w-small: 750px;
$w-medium: 1200px;
$w-large: 1400px;

$w-max: 1800px; // max page width
$w-screen-max: 1920px;

$screen: "only screen";

//$landscape: "#{$screen} and (orientation: landscape)";
//$portrait: "#{$screen} and (orientation: portrait)";

$small: "#{$screen} and (max-width: #{$w-small})";
//$small-landscape: "#{$landscape} and (max-height: #{$w-small})";

$small-medium: "#{$screen} and (max-width: #{$w-medium})";
$small-large: "#{$screen} and (max-width: #{$w-large})";

$medium: "#{$screen} and (min-width: #{$w-small  + 1px}) and (max-width: #{$w-medium})";
$medium-large: "#{$screen} and (min-width: #{$w-small  + 1px}) and (max-width: #{$w-large})";
$medium-up: "#{$screen} and (min-width: #{$w-small  + 1px})";

$large: "#{$screen} and (min-width: #{$w-medium + 1px}) and (max-width: #{$w-large})";
$large-up: "#{$screen} and (min-width: #{$w-medium + 1px})";

$xlarge: "#{$screen} and (min-width: #{$w-large + 1px})";

$oversized: "#{$screen} and (min-width: #{$w-screen-max + 1px})";

//@media #{$medium} {}

// ---------------------------------------- mixins

@mixin body-common {
  color: $c-body-text;
  background-color: $bc-body;
  font-family: $font-sans;
  font-size: $fs-body;
  text-align: center;
  min-height: 100vh;
}

@mixin body-styled {
  background-image: url("../assets/avm-home-bg-2000x1333.jpg");
  background-position: top center;
  background-repeat: no-repeat;
  background-size: cover;
  background-clip: content-box;
  background-attachment: fixed;
}

@mixin input-decor {
  border: 1px solid $c-grey-lt;
  border-radius: 4px;
  padding: 1px 0;
  font-weight: $fw-bold;
}

@mixin flex-row {
  display: flex;
  flex-flow: row nowrap;
}

@mixin flex-row-full {
  @include flex-row;
  justify-content: space-between;
}

@mixin flex-row-even {
  @include flex-row;
  justify-content: space-evenly;
}

@mixin flex-center {
  display: flex;
  flex-flow: row nowrap;
  justify-content: center;
  align-items: center;
}

@mixin flex-column {
  display: flex;
  flex-flow: column;
}

@mixin link-style {
  text-decoration: none;
  color: $c-grey-dk-2;
  font-family: inherit;
  font-weight: inherit;
  font-size: inherit;

  &:not(:disabled) {
    &:hover {
      color: $c-high;
    }
  }
}

@mixin avm-button {
  font: $fw-bold 18px $font-sans;
  text-transform: capitalize;
  text-decoration: none;
  color: #fff;
  background-color: $c-high-lt;
  padding: 0.75em 1.5em;
  border: 0;
  border-radius: 4px;
  box-shadow: 1px 1px 4px #777;

  &.fr {
    padding: 0.75em;
  }

  &:disabled,
  &.disabled {
    background-color: #ccc;
    box-shadow: none;
    cursor: not-allowed;
  }

  @if not $is-opta {
    &:active {
      color: $c-high-lt;
      background-color: $c-white;
      box-shadow: none;
    }
  }

  @media #{$small-medium} {
    font-size: 0.95rem;
    padding: 0.5rem 1rem;

    &.fr {
      padding: 0.5rem;
    }
  }
}

@mixin bare-button {
  border: 0;
  border-radius: 0;
  padding: 0;
  background-color: transparent;
}

@mixin link-style-button {
  @include bare-button;
  @include link-style;
  display: inline;
}

@mixin company-logo {
  @if $is-opta {
    background-image: url("../assets/opta-logo.png");
    background-repeat: no-repeat;
    background-size: contain;
    width: 160px;
    height: 80px;

    &::after {
      content: " ";
    }
  } @else {
    display: none;
  }
}

// appending colon (en/fr versions)
@mixin colon-after {
  &::after {
    content: ":";
  }

  .fr &::after {
    content: "\00a0:";
  }
}

@mixin confidence {
  text-transform: capitalize;

  &.high {
    color: $c-green;
  }

  &.medium {
    color: $c-high;
  }

  &.low {
    color: $c-grey-dk-2;
  }
}

// environmental risk colors
@mixin risk-no {
  background-color: $c-grey-dk-3;
  color: $c-white;
}

@mixin risk-low {
  background-color: $c-green;
  color: $c-white;
}

@mixin risk-medium {
  background-color: $c-yellow;
  color: $c-grey-dk;
}

@mixin risk-high {
  background-color: $c-red;
  color: $c-white;
}

// information show handle (shape, size and colors only
@mixin char-icon {
  font-weight: $fw-bold;
  font-size: 16px;
  line-height: 1.2;
  background-color: $c-high-lt;
  color: #fff;
  width: 20px;
  height: 20px;
  border: 0;
  border-radius: 10px;
  display: inline-block;
  text-align: center;
  vertical-align: middle;
}

@mixin question-icon {
  @include char-icon;

  &::after {
    content: "?";
  }
}

@mixin info-icon {
  @include char-icon;

  &::after {
    content: "i";
  }
}
