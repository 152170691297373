@import "../presets";

.fixed-form-wrap {
  position: fixed;
  top: 200px;
  right: 0;
  bottom: 250px;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  z-index: 2;

  label,
  .text-input {
    display: block;
    min-width: 320px;
    margin: 4px 0;
  }

  label {
    text-transform: lowercase;
    font-style: oblique;
    font-size: $fs-body;
    text-align: center;
  }

  .form-section {
    margin: 20px 0;
  }

  .form-line {
    margin: 10px 0;
  }

  .text-input {
    outline: none;
    font-size: 1.1rem;
    border: 1px solid $c-grey-lt;
    padding: 4px 8px;
    border-radius: 4px;
    background-color: transparent;

    &:disabled {
      background-color: $c-grey-lt;
      color: $c-grey-dk-2;
    }
  }

  fieldset {
    position: relative;
    border: 0;
    background: #fff;
    padding: 16px 64px;

    .error-message {
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 8px;
      font: oblique 0.9rem $font-sans;
      color: $c-high;
      text-align: center;
    }
  }

  .submit-box {
    text-align: center;
  }

  .link {
    color: $c-high;
  }
}
