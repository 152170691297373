@import "../presets";

.page-header {
  @include flex-row-full;

  &.with-logo.expanded {
    align-items: center;
  }

  .iclarify-logo {
    display: inline-block;
    margin: 8px 24px 4px;

    img {
      width: 400px;
    }
  }

  .header-nav {
    margin: 0.25rem;
    display: inline-block;
  }

  &.no-logo .header-nav {
    display: block;
    width: 100%;
  }
}

.second-line {
  margin: 12px 16px;

  .big-button {
    font-size: 16px;
    padding: 4px 24px;
    background-size: 18px;
    background-position: 12px center;
    padding-left: 48px;
  }
}
