@import "../presets";

.search-btn {
  border: 0;
  background-position: center center;
  background-size: contain;
  padding: 0;
  width: 24px;
  height: 24px;
  background-color: transparent;
  opacity: 1;
  cursor: pointer;

  &::after {
    content: " ";
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.5;
  }
}

.search-widget {
  position: relative;

  .input-combo {
    position: absolute;
    top: 0;
    z-index: 10;
    border: 0;
    width: 100%;
    padding: 0.2rem 0;
    border-radius: 4px;
    background-color: $c-white;
  }

  .search-message {
    position: absolute;
    top: 48px;
    z-index: 11;
    color: $c-grey-dk;
    background: $c-grey-lt-2;
    text-align: left;
    font-size: $fs-body;
    font-style: oblique;
    padding: 4px 16px;
    border-radius: 4px;
  }

  .input-box {
    position: relative;
    padding-right: 24px;

    .search-btn {
      position: absolute;
      top: 6px;
      right: 8px;
    }
  }
  .hints-dropdown {
    max-height: 60vh;
    overflow-y: scroll;
    z-index: 10;
  }

  .hint-item,
  input {
    font-size: 1rem;
    padding: 8px 0 10px 20px;

    text-align: left;
  }

  .hint-item {
    width: 100%;
  }

  input {
    display: inline-block;
    font-weight: $fw-bold;
    border: 0;
    width: calc(100% - 40px);

    // for placeholder only
    &.blank {
      font-size: 0.85rem;
      font-weight: $fw-normal;
      font-style: oblique;
      color: $c-grey-dk-2;
    }
  }

  .hint-item {
    color: $c-body-text;
    cursor: pointer;

    &.current {
      background-color: $c-grey-lt;
      color: #fff;
    }

    &:last-child {
      border: 0;
    }

    i {
      font-style: normal;
    }
  }
}

.advanced-search-handle {
  position: absolute;
  top: 4rem;
  right: 0;

  .big-button {
    background-color: $c-grey;
    color: $c-grey-dk;
    padding: 6px 12px;
  }
}

.advanced-search {
  position: absolute;
  top: 5rem;
  left: 0;
  width: 100%;
  padding: 4px;
  width: 100%;
  border-radius: 4px;
  background: $c-white;
}

.advanced-search-box {
  position: relative;
  @include flex-row;

  .search-btn {
    position: absolute;
    top: 10px;
    right: 6px;
  }

  .input-cell {
    width: 110px;
    margin-right: 0.5rem;

    &.scrollable {
      .dd-menu {
        max-height: 10rem;
        overflow-y: scroll;
      }
    }
  }
}
