@import "../presets";

.search-tips {
  position: absolute;
  top: 3rem;
  left: 0.2rem;
}

.search-tips-handle {
  position: relative;
  text-align: left;

  .search-tips-content {
    display: none;
  }

  &:hover {
    .search-tips-label {
      color: $c-high;
    }
    .search-tips-content {
      display: block;
      z-index: 99;
    }
  }
}

.search-tips-label {
  padding-left: 1.3rem;
  font-size: 0.85rem;
  color: $c-body-text;
  background-position-x: 0;
  background-size: contain;
  cursor: default;
}

.search-tips-content {
  position: absolute;
  top: 1.5rem;
  left: 0;
  box-sizing: content-box;
  width: auto; //524px;
  padding: 0.25rem 0.5rem;
  background-color: #fff;
  border-radius: 0.25rem;

  li {
    list-style-position: inside;
    font-size: 0.85rem;
    white-space: nowrap;
    margin: 0.4rem 0;
  }
}
