// Property review modal
@import "../presets";

.property-review {
  position: relative;
  text-align: right;
  padding-top: 3.2rem;

  .big-button {
    padding: 0.75rem 2rem;
    text-align: center;
    text-transform: none;
  }

  @media #{$large-up} {
    .overlord-button {
      display: none;
    }
  }

  @media #{$small-medium} {
    @include flex-row;
    justify-content: right;
    align-items: center;
    min-width: 300px;

    &.fr {
      min-width: 320px;
    }

    .big-button {
      padding: 12px;

      &.overlord-button {
        margin-right: 1rem;
      }
    }
  }
}

.property-review-modal {
  position: absolute;
  z-index: 2;
  top: 5rem;
  right: 5rem;
  width: 400px;
  padding: 0;
  background: #fff;
  border: 0;
  border-radius: 32px;
  box-shadow: 1px 1px 4px #777;
  overflow: hidden;

  textarea {
    display: block;
    width: 90%;
    margin: 1rem auto 0;
    height: 8rem;
    padding: 2px;
  }
}

.prm-header {
  position: relative;
  background: $c-high;
  width: 100%;
  padding: 0.5rem;
  text-align: center;

  h3 {
    color: $bc-body;
  }
}

.prm-counter {
  padding: 0.25rem 1rem;
  font-size: 0.8rem;
  color: #666;
}

.prm-controls {
  @include flex-row-even;
  padding: 0.5rem 0 1rem;
  width: 100%;

  button {
    @include avm-button;
    font-size: 1rem;
  }
}
