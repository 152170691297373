@import "../presets";

.property-images {
  .image-box {
    margin: 12px 0;

    img {
      width: 100%;
      height: auto;

      &.linked {
        cursor: pointer;
      }
    }
  }

  .image-placeholder {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    height: 300px;
    background-color: $c-grey-lt;

    p {
      font-size: 1rem;
      letter-spacing: 2px;
      color: #404040;
      text-transform: capitalize;
      text-shadow: 2px 4px 4px $c-grey-dk-2;
    }

    &::after {
      content: " ";
    }
  }

  @media #{$small-medium} {
    @include flex-row;
    width: 600px;
    height: 200px;

    .image-box {
      margin: 0;
      height: 100%;
      margin-right: 16px;

      img {
        width: auto;
        height: 100%;
      }
    }

    .image-placeholder {
      height: 100%;
    }
  }
}
