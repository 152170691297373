@import "../presets";

.property-view-main {
  position: relative;
}

.property-explained {
  position: absolute;
  top: 1rem;
  right: 0;
  border: 2px solid $c-grey-dk-2;
  text-align: left;
  width: 95%;
  //max-width: 100%;
  max-height: 600px;
  background-color: $c-white;
  overflow-y: scroll;
  padding: 1rem;
  z-index: 99;
  cursor: pointer;

  h3 {
    font-size: 1rem;
    text-transform: none;
    color: $c-high;
  }

  ol,
  ul {
    padding-left: 1rem;
  }

  ul li {
    list-style-type: disc;
  }

  p,
  li {
    font-size: 0.85rem;
    line-height: 1.5;
    margin: 0.5rem 0;
  }

  @media #{$large-up} {
    top: -1rem;
    right: 1%;
  }
}
