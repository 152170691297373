@import "../presets";

.dd-comp {
  @include input-decor;
  position: relative;
  text-align: left;
  padding-left: 8px;

  

  .dd-main {
    position: relative;
    overflow: hidden;
    color: #333;
    outline: none;
    padding: 0;
    transition: all 200ms ease;
  }

  &.disabled {
    background-color: $c-grey-lt-2;

    .dd-main {
      color: $c-grey-dk-2;
    }
  }

  .dd-value,
  .dd-option {
    font-size: 14px;
    font-weight: $fw-bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .dd-value {
    padding-right: 20px;
    height: 20px;
  }

  .dd-option {
    color: rgba(51, 51, 51, 0.8);
    cursor: pointer;
    display: block;
    padding: 2px 0 2px 8px;

    &:hover,
    &.selected {
      background-color: #dcdcdc;
      color: #404040;
    }

    &.disabled {
      color: #ccc;
      background-color: inherit;
      font-style: oblique;
    }

    &.initial {
      color: black;
    }
  }

  .dd-button {
    position: absolute;
    right: 8px;
    top: 8px;
    width: 0;
    border-color: #999 transparent transparent;
    border-style: solid;
    border-width: 5px 5px 0;
    display: block;
    height: 0;
  }

  &.expanded {
    .dd-button {
      border-color: transparent transparent #999;
      border-width: 0 5px 5px;
    }
  }

  .dd-menu {
    position: absolute;
    top: 100%;
    left: 0;
    width: 100%;
    z-index: 1000;
    background-color: white;
    border: 1px solid #ccc;
    box-shadow: 0 1px 0 rgba(0, 0, 0, 0.06);
    margin-top: -1px;
  }

  .novalue {
    font-weight: normal;
    font-style: oblique;
    color: $c-grey-dk-2;
  }
}
