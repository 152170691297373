@import "../presets";

$spinner-thickness: 8px;
$spinner-radius: 64px + $spinner-thickness;
$spinner-size: $spinner-radius * 2;

$spinner-top: calc(50vh - $spinner-radius);
$spinner-left: calc(50vw - $spinner-radius);

.spinner {
  @include flex-center;
  position: fixed;
  top: $spinner-top;
  left: $spinner-left;
  width: $spinner-size;
  height: $spinner-size;
  z-index: 999;
}

.spinner-ripple {
  display: inline-block;
  position: relative;
  width: $spinner-size;
  height: $spinner-size;
}
.spinner-ripple div {
  position: absolute;
  border: $spinner-thickness solid $c-high;
  opacity: 1;
  border-radius: 50%;
  animation: spinner-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}
.spinner-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

body.styled .spinner-ripple div {
  border-color: $c-white;
}

@keyframes spinner-ripple {
  0% {
    top: $spinner-radius;
    left: $spinner-radius;
    width: 0;
    height: 0;
    opacity: 0.75;
  }
  100% {
    top: 0;
    left: 0;
    width: $spinner-size;
    height: $spinner-size;
    opacity: 0;
  }
}
