@import "../presets";

.property-address-box {
  display: inline-block;
  text-align: left;
  padding: 24px 0 0 60px;
  background-size: 30px;
  background-position: 20px center;
}

.property-address-box,
.property-header-controls {
  flex: 1;
}

.pab--street {
  font-size: 20px;
  font-weight: $fw-bold;
}

.pab--separator {
  color: $c-white; // invisible
}

.pab--city {
  font-size: 16px;
  text-transform: capitalize;
}

.pab--search {
  font-size: 20px;
  color: $c-grey-dk-2;
  text-transform: capitalize;
  text-align: left;
}

.property-header {
  width: 100%;
  padding-right: 1rem;
  margin-bottom: 6px;
  padding-bottom: 6px;

  .property-header-top,
  .infobox {
    @include flex-row-full;
    width: 100%;
  }

  .property-header-top {
    align-items: center;
  }

  .info-table {
    margin-top: 1rem;
    max-width: 70%;
    padding-right: 1rem;

    .td {
      text-align: left;
      padding: 0.25rem 0;
    }

    .info-label {
      @include colon-after;
      font-size: 1rem;
      font-weight: $fw-bold;
      padding-right: 0.5rem;
      white-space: nowrap;
      min-width: 12rem;
    }

    .info-text {
      line-height: 1.1;
      font-size: 0.9rem;
    }

    &.rowadded .td {
      padding: 0.2rem 0;
    }
  }
}

.header-controls {
  display: inline-block;
  text-align: right;

  .big-button {
    padding: 12px 24px;
    margin-left: 8px;
  }
}
