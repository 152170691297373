@import "../presets";

.search-wrap {
  position: relative;
  width: 660px;
  margin: 0 auto;
}

.brand-logo {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 150px;
  background-position: center center;
  background-repeat: no-repeat;
  background-size: contain;
}

.brand-footer {
  position: absolute;
  left: 0;
  bottom: 40px;
  width: 100%;
  text-align: center;
  font-size: 18px;
  font-weight: bold;
  z-index: 1;
}

.search-box {
  position: absolute;
  top: 20vh;
  left: 0;
  width: 100%;
}
