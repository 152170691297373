@import "../presets";

.property-features {
  padding: 0 32px 0 0;
  max-width: 800px;

  .stats-item {
    display: table-row;
  }

  .feature-label,
  .feature-value {
    font-size: 1rem;
    font-weight: $fw-bold;
    width: 180px;
    max-width: 180px;
    min-width: 180px;
    text-align: left;
  }

  .feature-label {
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
  }
}

.pf--content {
  display: flex;
  flex-flow: row nowrap;
  justify-content: left;
  align-items: flex-start;
  padding: 0;
  margin-bottom: 0.5rem;

  .table-cell {
    padding: 6px 0;
    white-space: nowrap;
  }

  .col1 {
    margin-right: 2rem;
  }

  .col2 {
    .feature-label {
      width: 200px;
    }
    .table-cell {
      padding: 5px 0;
    }
  }

  input {
    margin: 0;
  }

  .value-text,
  .value-readonly,
  .dd-value,
  .dd-option {
    font-size: 14px;
    font-weight: $fw-bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .dd-value {
    padding-right: 20px;
    height: 20px;
  }

  .dd-option {
    &.initial {
      color: black;
    }
  }
}

.feature-value {
  @include input-decor;

  //display: inline-block;

  .novalue {
    font-weight: normal;
    font-style: oblique;
    color: $c-grey-dk-2;
  }

  &,
  .dd-option {
    padding-left: 8px;
  }

  &.value-readonly {
    border-color: transparent;
  }

  &.invalid {
    border: 1px solid $c-high;
    background-color: lighten($color: $c-high, $amount: 48);
  }

  .dd-menu {
    left: 0;
  }

  .nodata {
    color: $c-grey-dk-2;
  }

  .initial {
    color: black;
  }
}

.dirty {
  color: $c-high;
}

.disabled .dirty {
  color: mix($c-grey-lt-2, $c-high, 50%);
}

.property-view-main.condensed {
  .feature-label,
  .feature-value {
    font-size: 0.95rem;
    width: 172px;
    max-width: 172px;
  }

  .feature-source {
    font-family: $font-sans;
    font-size: 0.8rem;
    margin: 1px;
    text-align: center;
    padding: 1px;
    width: 20px;
    max-width: 20px;
    overflow: hidden;
    color: $c-black;
    background-color: $c-grey-lt-2;
    border-radius: 2px;
  }
}
