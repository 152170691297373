@import "../presets";

$verisk-banner-en: url("../../assets/Banner_AVM.jpg"); // 914x325 -- 2.8
$verisk-banner-fr: url("../../assets/Banner_AVM-FR.jpg");

$banner-width: 750px;
$banner-height: 268px;

@media #{$small} {
  $banner-width: 400px;
  $banner-height: 138px;
}

.verisk-banner-wrap {
  @if $is-opta {
    display: block;
    width: 100%;
    margin-top: 2rem;
  } @else {
    display: none;
  }
}

.verisk-banner {
  display: block;
  width: $banner-width;
  height: $banner-height;
  margin: 0 auto;
  position: relative;
  cursor: pointer;

  background-image: $verisk-banner-en;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;

  @media #{$small} {
    width: $banner-width / 1.5;
    height: $banner-height / 1.5;
  }

  &.fr {
    background-image: $verisk-banner-fr;
  }

  .verisk-info-body {
    position: absolute;
    bottom: 1rem;
    left: 0;
    width: $banner-width;
    height: auto;
    padding: 1rem 1rem;
    background-color: #fff;

    text-align: left;
    cursor: default;

    @media #{$small} {
      width: $banner-width / 1.5;
    }

    h6 {
      font-size: 1.4rem;
      font-weight: $fw-normal;
      margin: 0;
      padding: 0 0 1rem;
    }

    .verisk-info-text {
      padding: 1rem 0;
      border: 1px solid $c-grey-lt;
      border-width: 1px 0;

      p {
        font-size: 1rem;
        margin: 0.2rem 0;
        line-height: 1.6;
      }

      a {
        color: #00358e;
        text-decoration: underline;

        &:hover {
          color: #2a7de1;
          text-decoration: none;
        }
      }
    }

    .info-bottom {
      margin-top: 1rem;
    }
  }
}
