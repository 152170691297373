@import "../presets";

.property-view {
  h2 {
    margin-top: 0;
  }

  @media #{$large-up} {
    @include flex-row;
    justify-content: flex-start;
  }
}

// small to medium

@media #{$small-medium} {
  .property-view {
    display: block;
    width: 100%;

    .property-view-images {
      @include flex-row;
      justify-content: flex-start;
      width: 100%;
    }
  }
}

// small screen

@media #{$small-medium} {
  .property-view-images {
    .pv-overlord {
      display: none;
    }
  }
}

// larger screen

@media #{$large-up} {
  .property-view {
    @include flex-row;
    justify-content: flex-start;
  }

  .property-view-images {
    flex: 1;
    max-width: 300px;
    margin-right: 16px;
    text-align: center;

    .error-text {
      display: block;
      margin: 4rem 2rem;
    }
  }

  .property-view-main {
    flex: 2;
  }
}


