@import "../presets";

.property-data-error {
  @include flex-center;
  //width: 100%;
  min-height: 350px;
  text-align: center;

  margin: 1rem auto;

  h3,
  p {
    color: $c-error;
  }

  h3 {
    text-transform: uppercase;
  }

  p {
    font-size: 1rem;
    line-height: 2.5;
  }

  .reset-btn {
    @include link-style-button;
    margin-top: 2rem;
    font-size: 1rem;
  }
}
