@import "../presets";

.property-valuation {
  width: 100%;

  &.avm-failed {
    margin-top: 1rem;

    .error-text {
      line-height: 2;
    }
  }

  .recalc-panel,
  .error-text {
    text-align: center;
  }
}

//---- simplified layout - Valuation/Confidence table
.property-valuation-table {
  background-color: $c-grey-lt-2;

  .th,
  .td {
    color: $c-grey-dk-2;
    padding: 0.25rem 1rem;
    font-weight: $fw-bold;
    white-space: nowrap;
    text-align: center;
    vertical-align: middle;
  }

  .th {
    font-size: 1.1rem;
  }

  .td {
    font-size: 1.2rem;
  }
}
//-----------

.property-valuation-top {
  @include flex-center;
  height: 4rem;
}

.property-valuation-label {
  text-align: right;
  width: 14rem;
  margin-right: 0.5rem;

  &.fr {
    width: 15rem;
  }

  &.combined::after {
    content: ":";
  }

  &.explained {
    position: relative;

    .explained-handle {
      @include question-icon;
      position: absolute;
      top: 2px;
      left: 0;
      cursor: pointer;
    }
  }
}

.property-valuation-confidence-box {
  width: 8rem;
  height: 3.2rem;
  text-align: center;
  padding: 0.25rem 1rem;
  background-color: $c-grey-lt-2;

  &.empty {
    background-color: transparent;

    &::after {
      content: "\a0";
    }
  }

  div {
    font-size: 1.1rem;
    font-weight: $fw-bold;
  }
}

.confidence-title {
  color: $c-grey-dk-2;
}

.confidence-value {
  @include confidence;
}

.property-valuation-main {
  margin: 0.5rem auto;
  border: 1px solid $c-grey-dk-2;

  .th,
  .td {
    border-left: 1px solid $c-grey-dk-2;
    text-align: center;
    white-space: nowrap;
    padding: 0.5rem 1rem;

    &:first-of-type {
      border: 0;
    }

    &.property-valuation-range {
      width: 14rem;
    }

    &.property-valuation-valuation {
      width: 9rem;
    }

    &.property-valuation-rental {
      width: 14rem;
    }
  }

  .td {
    font-weight: $fw-bold;
    color: $c-black;
    height: 1.6rem;

    &.tip {
      color: $c-grey;
      font-style: oblique;
    }
  }
}

.property-valuation-header {
  background-color: $c-grey-lt;
  border-bottom: 1px solid $c-grey-dk-2;

  button {
    @include bare-button;
    font-size: 1rem;
    color: $c-high;
    text-decoration: underline;
    text-transform: none;

    &:hover,
    &:disabled,
    &:active {
      text-decoration: none;
    }

    &:disabled {
      color: $c-grey-dk-2;
    }
  }
}
