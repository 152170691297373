@import "../presets";

$w-mainview: 1100px;

$w-side: 200px;
$w-side-collapsed: 68px;
$w-separator: 18px;

.avm-layout {
  width: 100%;
  margin: 0 auto;
  min-height: 600px;
  padding: 0;

  > .table-row > .table-cell {
    vertical-align: top;

    // navigation width toggle element
    &.avm-layout-toggle {
      height: 100%;
      vertical-align: middle;
      width: $w-separator;
      background: $c-grey-lt-2;
      color: $c-high;
      &::before {
        content: "◄";
      }
      &.collapsed::before {
        content: "►";
      }
    }
  }
}

.avm-layout-side {
  width: $w-side;
  padding: 88px 0 0;
  box-shadow: inset -4px 8px 8px $c-grey;
  border: 0;
  background: linear-gradient(180deg, $c-grey, $c-grey-lt-2 12px);

  &.collapsed {
    width: $w-side-collapsed;
  }
}

.content-wrapper {
  width: 100%;
  padding: 0;
  background: linear-gradient(180deg, $c-grey, $c-white 12px);

  .mainview {
    padding: 16px 0 12px 16px;
    width: 100%;
  }
}

.avm-layout-nav {
  padding: 8px 0 4px;
  background: linear-gradient(270deg, $c-grey-lt, $c-white 12px);

  .nav-item {
    @include flex-row;
    align-items: stretch;
    border: 0;
    background: linear-gradient(270deg, $c-grey, $c-grey-lt-2 12px);
    text-align: left;
    padding: 0;
    margin: 0 0 8px;
    font-size: 17px;

    &.current {
      color: #fff;
      background: linear-gradient(270deg, mix($c-grey-dk, $c-high-lt), $c-high-lt 12px);
      cursor: not-allowed;
    }

    &.disabled {
      &:not(.current) {
        color: $c-grey;
      }
      cursor: not-allowed;
    }

    span {
      display: inline-block;
      height: 44px;

      &.item-icon {
        width: 62px;
        background-size: 48px;
        background-position: center center;

        &.bg-icon-valuation {
          background-size: 32px;
        }

        &.bg-icon-metrics {
          background-size: 40px;
        }

        &.bg-icon-environ {
          background-size: 40px;
        }
      }

      &.item-text {
        width: 164px;
        line-height: 2.6;
        white-space: nowrap;
        overflow: hidden;
      }
    }
  }

  &.collapsed {
    width: 68px;

    .item-text {
      display: none;
    }
  }
}

.fr .avm-layout-side .nav-item {
  font-size: 16px;
}
