@import "../presets";

.modal {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  @include flex-center;
}

.modal-box {
  min-width: 200px;
  min-height: 40px;
  background: white;
  color: $c-grey-dk-2;
  border: 1px solid $c-grey-lt;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 4px 4px 24px $c-grey-dk;

  .bottom {
    margin-top: 8px;
  }

  button {
    font-size: 0.9rem;
    padding: 2px 16px;
    border: 0;
    border-radius: 8px;
    background: $c-grey-lt-2;
    color: $c-grey-dk-2;

    &:hover {
      background: $c-grey-lt-2;
      color: $c-high;
    }
  }

  iframe {
    border: 0;
    padding: 0;
    overflow: hidden;
  }
}

.modal-content {
  padding: 32px;
  padding-bottom: 16px;
}

.modal-alert {
  text-align: center;
}
