// Top navigation items
@import "../presets";

.site-nav {
  text-align: right;
  white-space: nowrap;

  .site-nav-item,
  .language {
    cursor: pointer;
    display: inline-block;
    font: $fw-bold 16px $font-sans;
    letter-spacing: 0.5px;
    border: 0;
    padding: 1px 16px;

    &:not(:first-of-type) {
      border-left: 1px solid $c-grey-dk-2;
    }
  }
  .site-nav-item {
    @include link-style;
  }
}
